import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { IApiListQueryParameter, BaseDatatableStateSaveMode } from "@impacgroup/angular-baselib";
import { map } from "rxjs/operators";
import { plainToClass } from "class-transformer";
import { Meeting } from "../../api-models/Meeting";

export interface IMeetingConfigService {
    meetingsAPI: string;
    utcDateFormat: string;
    datatableStateSaveMode: BaseDatatableStateSaveMode;
}

export const MeetingConfigService = new InjectionToken<IMeetingConfigService>("MeetingConfig");

@Injectable()
export class MeetingsService {

    public UTCDATEFORMAT: string = "";
    public datatableStateSaveMode: BaseDatatableStateSaveMode;

    constructor(
        @Inject(MeetingConfigService) private meetingConfig: IMeetingConfigService,
        private http: HttpClient,
        private oauthStorageAdapter: OAuthStorageAdapter
    ) {
        this.UTCDATEFORMAT = this.meetingConfig.utcDateFormat;
        this.datatableStateSaveMode = this.meetingConfig.datatableStateSaveMode;
    }

    public meetings(params: IApiListQueryParameter): Observable<{list: Meeting[], count: number, total: number}> {
        return this.http.get<any>(this.getMeetingsServiceUrl(""), {
            headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
            params: params as any,
            observe: "response"
        }).pipe(
            map(result => {
                let count = result.body.length;
                let total = result.body.length;
                try {
                    count = parseInt(result.headers.get("X-Pagination-Count"), 10);
                } catch (e) {}
                try {
                    total = parseInt(result.headers.get("X-Total-Count"), 10);
                } catch (e) {}

                return {
                    list: plainToClass(Meeting, result.body as any[]),
                    count: count,
                    total: total
                };
            })
        );
    }

    private getMeetingsServiceUrl(postfix: string): string {
        return this.meetingConfig.meetingsAPI + postfix;
    }
}
