import * as tslib_1 from "tslib";
import { BaseComponent } from "@impacgroup/angular-baselib";
var MeetingsMainComponent = /** @class */ (function (_super) {
    tslib_1.__extends(MeetingsMainComponent, _super);
    function MeetingsMainComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return MeetingsMainComponent;
}(BaseComponent));
export { MeetingsMainComponent };
