import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MeetingsService } from "./meetings.service";
import { BaseDatatableComponent } from "@impacgroup/angular-baselib";
import { MeetingsMainComponent } from "./meetings.main.component";
var MeetingsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(MeetingsComponent, _super);
    function MeetingsComponent(meetingsService, translateService, parent) {
        var _this = _super.call(this, translateService, {
            stateSaveMode: meetingsService.datatableStateSaveMode,
            stateSaveComponent: parent,
            stateSaveStorageKey: "dtstate_meetings_list"
        }) || this;
        _this.meetingsService = meetingsService;
        _this.translateService = translateService;
        _this.UTCDATEFORMAT = "";
        _this.rows = [];
        _this.dtOptions.order = [[5, "desc"]];
        _this.UTCDATEFORMAT = _this.meetingsService.UTCDATEFORMAT;
        return _this;
    }
    MeetingsComponent.prototype.ngOnInit = function () {
    };
    MeetingsComponent.prototype._dataTableTrigger = function () {
        var _this = this;
        return function (dataTableParameters, callback) {
            // get api query parameters by datatable
            var queryParams = _this.getApiQueryParamsByDataTable(dataTableParameters);
            _this.subscriptions.push(_this.meetingsService.meetings(queryParams).subscribe(function (result) {
                _this.rows = result.list;
                callback({
                    recordsTotal: result.total,
                    recordsFiltered: result.count,
                    data: []
                });
            }));
        };
    };
    MeetingsComponent.prototype._dataTableColums = function () {
        return [
            { data: "start", orderable: true },
            { data: "end", orderable: true },
            { data: "summary", orderable: true },
            { data: "description", orderable: false },
            { data: "representative", orderable: false },
            { data: "created", orderable: true },
            { data: "updated", orderable: true }
        ];
    };
    return MeetingsComponent;
}(BaseDatatableComponent));
export { MeetingsComponent };
