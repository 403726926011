import * as tslib_1 from "tslib";
import { Exclude, Type } from "class-transformer";
import { nonLambdaReturn } from "@impacgroup/angular-baselib";
var Attendee = /** @class */ (function () {
    function Attendee() {
        this.email = "";
        this.firstname = "";
        this.lastname = "";
    }
    return Attendee;
}());
export { Attendee };
var Representative = /** @class */ (function () {
    function Representative() {
        this.email = "";
        this.firstname = "";
        this.lastname = "";
    }
    return Representative;
}());
export { Representative };
var Meeting = /** @class */ (function () {
    function Meeting() {
        this.start = new Date();
        this.end = new Date();
        this.summary = "";
        this.description = "";
        this.attendees = [];
        this.representative = new Representative();
    }
    Meeting.prototype.getRepresentativeFullname = function () {
        if (this.representative) {
            return this.representative.firstname + " " + this.representative.lastname + " (" + this.representative.email + ")";
        }
        return "deleted user";
    };
    tslib_1.__decorate([
        Type(nonLambdaReturn(Date)),
        tslib_1.__metadata("design:type", Date)
    ], Meeting.prototype, "start", void 0);
    tslib_1.__decorate([
        Type(nonLambdaReturn(Date)),
        tslib_1.__metadata("design:type", Date)
    ], Meeting.prototype, "end", void 0);
    tslib_1.__decorate([
        Type(nonLambdaReturn(Attendee)),
        tslib_1.__metadata("design:type", Array)
    ], Meeting.prototype, "attendees", void 0);
    tslib_1.__decorate([
        Type(nonLambdaReturn(Representative)),
        tslib_1.__metadata("design:type", Representative)
    ], Meeting.prototype, "representative", void 0);
    tslib_1.__decorate([
        Type(nonLambdaReturn(Date)),
        Exclude({ toPlainOnly: true }),
        tslib_1.__metadata("design:type", Date)
    ], Meeting.prototype, "created", void 0);
    tslib_1.__decorate([
        Type(nonLambdaReturn(Date)),
        Exclude({ toPlainOnly: true }),
        tslib_1.__metadata("design:type", Date)
    ], Meeting.prototype, "updated", void 0);
    return Meeting;
}());
export { Meeting };
