<div class="content__inner">
    <header class="content__title">
        <h1>{{ 'meetings.list.title' | translate }}</h1>
        <small>{{ 'meetings.list.subtitle' | translate }}</small>

    </header>

    <div class="card" *appHasPermission="'MEETINGS.LIST'">
        <div class="card-body">
            <h4 class="card-title">{{ 'meetings.list.card.title' | translate }}</h4>

            <table datatable class="table table-bordered" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th>{{ 'meetings.props.start' | translate }}</th>
                        <th>{{ 'meetings.props.end' | translate }}</th>
                        <th>{{ 'meetings.props.summary' | translate }}</th>
                        <th>{{ 'meetings.props.description' | translate }}</th>
                        <th>{{ 'meetings.props.representative' | translate }}</th>
                        <th>{{ 'meetings.props.created' | translate }}</th>
                        <th>{{ 'meetings.props.updated' | translate }}</th>
                    </tr>
                </thead>
                <tbody *ngIf="rows?.length != 0">
                    <tr *ngFor="let row of rows">
                        <td>{{ row.start | date:UTCDATEFORMAT }}</td>
                        <td>{{ row.end | date:UTCDATEFORMAT }}</td>
                        <td>{{ row.summary }}</td>
                        <td>{{ row.description }}</td>
                        <td>{{ row.getRepresentativeFullname() }}</td>
                        <td>{{ row.created | date:UTCDATEFORMAT:'+0' }}</td>
                        <td>{{ row.updated | date:UTCDATEFORMAT:'+0' }}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="rows?.length == 0">
                    <tr>
                        <td colspan="7" class="no-data-available">{{ 'global.datatable.empty' | translate }}</td>
                    </tr>
                <tbody>
            </table>
        </div>
    </div>
</div>