import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { DataTablesModule } from "angular-datatables";

import { MeetingsComponent } from "./meetings.component";
import { MeetingsService, MeetingConfigService, IMeetingConfigService } from "./meetings.service";
import { TranslateModule } from "@ngx-translate/core";
import { OAuthModule } from "@impacgroup/angular-oauth-base";
import { Route, RouterModule } from "@angular/router";
import { MeetingsMainComponent } from "./meetings.main.component";

@NgModule({
    declarations: [
        MeetingsMainComponent,
        MeetingsComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        DataTablesModule,
        BsDropdownModule,
        ModalModule,
        NgSelectModule,
        TranslateModule,
        OAuthModule
    ],
    exports: [
    ],
    providers: [
    ]
})
export class MeetingsModule {
    static forRoot(meetingConfig: IMeetingConfigService): ModuleWithProviders {

        // returns the services as singletons throughout the app (for lazy loading purposes)
        return {
            ngModule: MeetingsModule,
            providers: [
                MeetingsService,
                {
                    provide: MeetingConfigService,
                    useValue: meetingConfig
                }
            ]
        };
    }

    static getRoutes(): Route[] {
        return [{
            path: "",
            component: MeetingsMainComponent,
            children: [{
                path: "",
                component: MeetingsComponent
            }]
        }];
    }
}
