import {NgModule} from "@angular/core";
import {Routes, RouterModule} from "@angular/router";
import {FullLayoutComponent} from "./layout/layouts/layout-full/layout.component";
import {SingleLayoutComponent} from "./layout/layouts/layout-single/layout.component";
import { AuthenticationGuard } from "@impacgroup/angular-oauth-base";
import { OrganizationsModule, RolesModule, UsersModule } from "@impacgroup/angular-access-management";
import { ProfileModule, ResetPasswordModule } from "@impacgroup/angular-selfservice";
import { LanguagesModule } from "@impacgroup/angular-languages";
import { MailTemplatesModule, SmtpMailAccountsModule } from "@impacgroup/angular-mailtemplates";
import { SystemLogMessagesModule, SystemJobPerformancesModule, SystemResourceSnapshotsModule, SystemErrorsModule } from "@impacgroup/angular-systemhealth";
import { CrashlogApplicationsModule, CrashlogMessagesModule, CrashlogErrorsModule } from "@impacgroup/angular-crashlogs";
import { MeetingsModule } from "./modules/meetings/meetings.module";


const routes: Routes = [
    {
        path: "",
        component: FullLayoutComponent,
        canActivate: [AuthenticationGuard],
        canActivateChild: [AuthenticationGuard],
        children: [
            // Home
            {
                path: "",
                redirectTo: "home",
                pathMatch: "full"
            },
            {
                path: "home",
                loadChildren: "./pages/home/home.module#HomeModule"
            },
            {
                path: "usermanagement",
                children: [
                    {
                        path: "",
                        redirectTo: "users",
                        pathMatch: "full"
                    },
                    {
                        path: "users",
                        children: UsersModule.getRoutes()
                    },
                    {
                        path: "roles",
                        children: RolesModule.getRoutes()
                    },
                    {
                        path: "organizations",
                        children: OrganizationsModule.getRoutes()
                    }
                ]
            },
            {
                path: "profile",
                children: ProfileModule.getRoutes()
            },
            {
                path: "languages",
                children: LanguagesModule.getRoutes()
            },
            {
                path: "mail",
                children: [
                    {
                        path: "",
                        redirectTo: "mailaccounts",
                        pathMatch: "full"
                    },
                    {
                        path: "mailaccounts",
                        children: SmtpMailAccountsModule.getRoutes()
                    },
                    {
                        path: "mailtemplates",
                        children: MailTemplatesModule.getRoutes()
                    }
                ]
            },
            {
                path: "meetings",
                children: MeetingsModule.getRoutes()
            },
            {
                path: "systemhealth",
                children: [
                    {
                        path: "",
                        redirectTo: "logs",
                        pathMatch: "full"
                    },
                    {
                        path: "logs",
                        children: SystemLogMessagesModule.getRoutes()
                    },
                    {
                        path: "jobs",
                        children: SystemJobPerformancesModule.getRoutes()
                    },
                    {
                        path: "resources",
                        children: SystemResourceSnapshotsModule.getRoutes()
                    },
                    {
                        path: "errors",
                        children: SystemErrorsModule.getRoutes()
                    }
                ]
            },
            {
                path: "crashlogs",
                children: [
                    {
                        path: "",
                        redirectTo: "crashlogapplications",
                        pathMatch: "full"
                    },
                    {
                        path: "crashlogapplications",
                        children: CrashlogApplicationsModule.getRoutes()
                    },
                    {
                        path: "crashlogerrors",
                        children: CrashlogErrorsModule.getRoutes()
                    },
                    {
                        path: "crashlogmessages",
                        children: CrashlogMessagesModule.getRoutes()
                    }
                ]
            }
        ]
    },
    {
        path: "",
        component: SingleLayoutComponent,
        children: [
            {
                path: "login",
                loadChildren: "./pages/login/login.module#LoginModule"
            },
            {
                path: "resetpassword",
                children: ResetPasswordModule.getRoutes()
            },
            {
                path: "404",
                loadChildren: "./pages/four-zero-four/four-zero-four.module#FourZeroFourModule"
            },
            {
                path: "**",
                redirectTo: "/404"
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
