import { Component, OnInit, Host } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MeetingsService } from "./meetings.service";
import { BaseDatatableComponent, IDataTableParameters } from "@impacgroup/angular-baselib";
import { Meeting } from "../../api-models/Meeting";
import { MeetingsMainComponent } from "./meetings.main.component";



@Component({
    selector: "app-meetings",
    templateUrl: "./meetings.component.html",
    styleUrls: ["meetings.component.scss"]
})
export class MeetingsComponent extends BaseDatatableComponent implements OnInit {
    public UTCDATEFORMAT: string = "";

    rows: Meeting[] = [];

    constructor(private meetingsService: MeetingsService, private translateService: TranslateService,
                @Host() parent: MeetingsMainComponent) {
        super(translateService, {
            stateSaveMode: meetingsService.datatableStateSaveMode,
            stateSaveComponent: parent,
            stateSaveStorageKey: "dtstate_meetings_list"
        });

        this.dtOptions.order = [[ 5, "desc" ]];
        this.UTCDATEFORMAT = this.meetingsService.UTCDATEFORMAT;
    }

    ngOnInit() {
    }

    _dataTableTrigger() {
        return (dataTableParameters: IDataTableParameters, callback) => {
            // get api query parameters by datatable
            const queryParams = this.getApiQueryParamsByDataTable(dataTableParameters);

            this.subscriptions.push(this.meetingsService.meetings(queryParams).subscribe((result) => {
                this.rows = result.list;

                callback({
                    recordsTotal: result.total,
                    recordsFiltered: result.count,
                    data: []
                });
            }));
        };
    }

    _dataTableColums() {
        return [
            { data: "start", orderable: true },
            { data: "end", orderable: true },
            { data: "summary", orderable: true },
            { data: "description", orderable: false },
            { data: "representative", orderable: false },
            { data: "created", orderable: true },
            { data: "updated", orderable: true }
        ];
    }
}
