import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { HttpLoadingService } from "../../global/api-handling/HttpLoadingService";
import { BaseComponent } from "@impacgroup/angular-baselib";
var PageLoaderComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageLoaderComponent, _super);
    function PageLoaderComponent(httpLoadingService) {
        var _this = _super.call(this) || this;
        _this.httpLoadingService = httpLoadingService;
        _this.loading = false;
        return _this;
    }
    PageLoaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.httpLoadingService.httpRequests.subscribe(function (result) {
            _this.loading = (result > 0);
        }));
    };
    return PageLoaderComponent;
}(BaseComponent));
export { PageLoaderComponent };
