import { Exclude, Type } from "class-transformer";
import { nonLambdaReturn } from "@impacgroup/angular-baselib";

export class Attendee {
    _id?: string;
    email: string = "";
    firstname: string = "";
    lastname: string = "";
}

export class Representative {
    _id?: string;
    email: string = "";
    firstname: string = "";
    lastname: string = "";
}

export class Meeting {
    _id?: string;

    @Type(nonLambdaReturn(Date))
    start: Date = new Date();
    @Type(nonLambdaReturn(Date))
    end: Date = new Date();
    summary: string = "";
    description: string = "";
    @Type(nonLambdaReturn(Attendee))
    attendees: Attendee[] = [];
    @Type(nonLambdaReturn(Representative))
    representative: Representative = new Representative();

    version?: number;

    @Type(nonLambdaReturn(Date))
    @Exclude({ toPlainOnly: true })
    created?: Date;

    @Type(nonLambdaReturn(Date))
    @Exclude({ toPlainOnly: true })
    updated?: Date;

    public getRepresentativeFullname(): string {
        if (this.representative) {
            return `${this.representative.firstname} ${this.representative.lastname} (${this.representative.email})`;
        }
        return "deleted user";
    }
}
