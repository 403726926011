import { Injector } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { translateLoaderService } from "./global/translate/translateloader.service";
import { HttpErrorInterceptor } from "./global/api-handling/HttpErrorInterceptor";
import { LOCATION_INITIALIZED } from "@angular/common";
export function appInitializerFactory(translate, injector) {
    return function () { return new Promise(function (resolve) {
        var locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
        locationInitialized.then(function () {
            translate.addLangs(["en"]);
            var langToSet = "en";
            translate.setDefaultLang("en");
            translate.use(langToSet).subscribe(function () {
                // console.log(`Successfully initialized '${langToSet}' language.'`);
            }, function (err) {
                console.error("Problem with '" + langToSet + "' language initialization.'");
            }, function () {
                resolve(null);
            });
        });
    }); };
}
export var httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
];
var ɵ0 = translateLoaderService;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
